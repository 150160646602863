@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;
	}
}

@font-face {
	font-family: 'customFont';
	src:
		local('CustomFont-Regular.ttf'),
		url('/font/CustomFont-Regular.ttf') format('truetype');
	font-display: swap;
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'customFont';
	src:
		local('CustomFont-Medium.ttf'),
		url('/font/CustomFont-Medium.ttf') format('truetype');
	font-display: swap;
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'customFont';
	src:
		local('CustomFont-SemiBold.ttf'),
		url('/font/CustomFont-SemiBold.ttf') format('truetype');
	font-display: swap;
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'customFont';
	src:
		local('CustomFont-Bold.ttf'),
		url('/font/CustomFont-Bold.ttf') format('truetype');
	font-display: swap;
	font-weight: 700;
	font-style: normal;
}

html {
	font-family: customFont, system-ui, sans-serif;
	font-weight: 400;
	font-size: 15px;
	overflow-x: hidden;
	@apply text-gray-600/90;
}

p {
	line-height: 1.65;
	@apply mb-5;
}

p:last {
	@apply mb-3;
}

strong {
	@apply text-gray-800;
}

.block__portalLink p {
	@apply mb-0;
}

.block__portalLink a {
	@apply border-b border-blue-300 text-blue-700 hover:bg-gray-200 hover:text-blue-500 transition duration-300;
}
